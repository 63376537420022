@import '~styles/mixins';
@import '~styles/variables';

.container {
  color: white;
  position: relative;
  text-align: left;
  height: 75vh;
  display: flex;
}

.containerBackground {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
  object-position: 80%;
}

.layout {
  @include layout;
  position: relative;
  height: 100%;
  flex-shrink: 1;
  width: 100%;
}

.header {
  font-family: $title;
  font-size: 62px;
  font-weight: 700;
  line-height: 60px;
  max-width: 650px;
  text-transform: uppercase;
}

.main {
  display: flex;
  align-items: flex-end;
  height: calc(100% - 70px);
}

.subheader {
  @include subheader;
  margin-top: 30px;
  max-width: 650px;
  display: none;
  text-align: left;
}

.topic {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  text-transform: uppercase;
}

@media (min-width: $screen-sm-min) {
  .topic {
    font-size: 18px;
  }

  .main {
    height: 600px;
  }

  .container {
    height: 840px;
  }

  .subheader {
    display: block;
  }

  .containerBackground {
    background-position: 0 -21px;
    object-position: initial;
  }

  .header {
    font-size: 100px;
    line-height: 95px;
  }
}
