@import '~styles/variables';
@import '~styles/mixins';

.dropdownOuterWrap {
  background: #0a0a0a;
  max-width: $app-width;
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 1000;
  display: none;

  &.mobile {
    background: transparent;
    height: 100vh;
    top: 0;
    display: block;
    padding: 0;
  }
}

.mobile {
  padding-left: 10vw;

  .slant {
    display: none;
  }

  .dropdown {
    flex-direction: column;
    @include layout;
    padding-top: 40px;
  }

  .titleBlock {
    border-right: none;
    max-width: 350px;
  }

  .linkBlock {
    margin-left: 0;
    padding-top: 15px;
  }

  .linkBody {
    line-height: 18px;
    font-size: 20px;
  }

  .linkHeader {
    font-size: 36px;
    font-family: $title;
    cursor: pointer;
  }

  .button {
    display: none;
  }

  .row {
    flex-direction: column;
    margin-left: 0;
  }

  .header {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0px;
    cursor: pointer;
    position: relative;

    &:before {
      border-right: 3px solid white;
      border-top: 3px solid white;
      content: '';
      display: inline-block;
      height: 5px;
      margin: 0px 8px 2px 0px;
      position: relative;
      transform: rotate(225deg);
      transition: all 0.15s ease-in-out;
      width: 5px;
    }
  }

  .subHeader {
    display: none;
  }

  .linkHeader {
    margin-bottom: 5px;
  }
}

.overlay {
  @include overlay;
  z-index: 2;
}

.dropdown {
  color: white;
  display: flex;
  margin: 0 auto;
  max-width: $container-width;
  margin: 50px 40px 40px;
  text-align: left;
}

.titleBlock,
.linkBlock {
  opacity: 0;
  transition: opacity 0.4s 0.2s;
}

.active .titleBlock,
.active .linkBlock {
  opacity: 1;
}

.titleBlock {
  border-right: 1px solid #777777;
  max-width: 300px;
  padding-right: 30px;
  padding-top: 25px;
  z-index: 1;
}

.header {
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
  margin-bottom: 20px;
}

.subHeader,
.linkBody {
  color: #777777;
}

.row {
  display: flex;
  width: 100%;
  gap: 24px;
  margin-left: 60px;
  margin-bottom: 30px;
}

.linkBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 25px;
  z-index: 1;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.linkHeader {
  margin-bottom: 20px;
}

.linkBody {
  font-size: 16px;
}

.button {
  @include learnButton(150px);
  color: white;
  margin-top: 40px;

  &.active {
    background-color: white;
    color: black;
  }
}

.slant {
  fill: #1d1d1d;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 165px;
}

@media (min-width: $screen-lg-min) {
  .dropdownOuterWrap {
    display: block;
  }
}
