@import '~styles/mixins';
@import '~styles/variables';

.container {
  background-image: url('~images/parallax/pink-flat.png');
  background-position: 0 -21px;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  height: calc(100vh - 250px);
  min-height: 900px;
  margin-bottom: 40px;

  @media screen and (max-width: $screen-md-min) {
    background-position: -250px -100px;
    height: 900px;
  }
  @media screen and (max-width: $screen-sm-min) {
    margin-bottom: 20px;
    background-position: -250px -100px;
    height: 700px;
  }
}

.layout {
  @include layout;
}

.header {
  font-family: $title;
  font-size: 90px;
  font-weight: 700;
  line-height: 90px;
  max-width: 200px;
  text-transform: uppercase;
}

.intro {
  display: flex;
  flex-direction: column;
  padding-top: 400px;
  @media screen and (max-width: $screen-md-min) {
    padding-top: 300px;
  }
  @media screen and (max-width: $screen-sm-min) {
    padding-top: 150px;
  }
}

.subheader {
  @include subheader;
  display: none;
  margin-top: 30px;
  max-width: 600px;
  text-align: left;
}

.phones {
  position: absolute;
  top: 0;
  will-change: transform;
}

.devices {
  position: relative;
  transform: translateY(0);
}

.phones {
  display: none;
}

.device {
  left: 0;
  position: absolute;
  top: 0;
}

.phone {
  left: 250px;
  top: 60px;
}

.laptop {
  left: -25px;
  top: 50px;
  width: 800px;
}

.tablet {
  left: 50px;
  top: -100px;
  width: 700px;
}

.shl {
  left: -150px;
  top: -100px;
  width: 1200px;
}

.inergy {
  left: 0px;
  top: 50px;
  width: 700px;
}

.barGroup {
  display: none;
}

.progressBar {
  background-color: #777;
  height: 2px;
  margin-right: 5px;
  margin-top: 40px;
  position: relative;
  transition: background-color 0s;
  width: 80px;

  &.active {
    background: white;
    transition: background-color 0.3s;
  }
}

.progressBarInner {
  background-color: $magenta;
  height: 2px;
  position: absolute;
  transition: none;
  width: 0px;
}

.active .progressBarInner {
  animation: progress 5s linear forwards;
}

// .active:first-child .progressBarInner {
//   animation: progress 5s linear forwards;
// }

.barTitle {
  display: none;
  color: $magenta;
  font-weight: bold;
  margin-top: 20px;
  text-align: left;
  position: relative;

  div {
    position: absolute;
    left: 0;
    // animation: slideLeft 0.5s;

    &:nth-child(1) {
      animation: slideLeft 5s ease-in-out infinite;
    }
  }
}

.mobileOnly {
  display: block;
  left: 0;
  position: absolute;
  top: 0;

  .device {
    display: none;
    height: 450px;
    top: 150px;
  }

  .phone {
    height: 500px;
    left: 240px;
    top: 80px;
  }
}

@media (min-width: $screen-md-min) {
  .mobileOnly * {
    display: none;
  }

  .phones {
    display: block;
    position: initial;
  }

  .devices {
    display: block;
    position: relative;
  }

  .subheader {
    display: block;
  }

  .intro {
    flex-direction: row;
    padding-top: 200px;
  }

  .progressBar {
    margin-top: 80px;
  }

  .header {
    font-size: 100px;
    line-height: 100px;
  }

  .barGroup {
    display: flex;
  }

  .barTitle {
    display: block;
  }
}

@keyframes progress {
  to {
    width: 100%;
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  10% {
    opacity: 1;
    transform: translateX(0);
  }
  90% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-10%);
  }
}
