@import '~styles/variables';

.App {
  background-color: white;
  margin: 0 auto;
  max-width: $app-width;
  overflow-x: hidden;
	padding-top: 80px;
  position: relative;
}

body {
  background-color: #000;
  color: #292a2d;
  font-family: 'Lato';
  font-size: 18px;
  overflow-x: hidden;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	border: 0;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
}
/* remember to define focus styles! */
:focus {
	outline: 0;
}
body {
	color: black;
}
ol, ul {
	list-style: none;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: separate;
	border-spacing: 0;
}
caption, th, td {
	font-weight: normal;
	text-align: left;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
}
blockquote, q {
	quotes: "" "";
}
a {
	color: black;
	text-decoration: none;
}
dl li {
  list-style-type: none;
	position: relative;
	padding-left: 30px;
	margin-bottom: 20px;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		margin-top: 15px;
		height: 10px;
		width: 10px;
		background-color: #4C5B6D;
	}

	&:last-child {
		margin-bottom: 0px;
	}
}
