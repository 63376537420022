@import '~styles/mixins.scss';

.container {
  background: url('~images/texture/simple-dark.png');
  background-size: cover;
  background-position: center;
  height: 360px;
  position: relative;
}

.layout {
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  @include layout;
}

.header {
  @include header;
  color: white;
  text-align: center;
  margin-bottom: 40px;
  font-size: 40px;
}

.button {
  @include learnButton(210px);
  color: white;
}

@media (min-width: $screen-md-min) {
  .header {
    font-size: 48px;
  }
}
