@import url('https://fonts.googleapis.com/css?family=Audiowide&display=swap');
@import '~styles/variables';

.container{
  top: 0%;
  left: 0%;
  height: 100vh;
  width: 100%;
  background-color: black;
  margin: 0px;
  overflow: hidden;
}

.title {
  position: absolute;
  top: 40%;
  left: 50%;
  margin: 160px 0 0 10px;
  font-size: 48px;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  display: block;
  text-align: center;
  color: #333;
  font-weight: 300;
  font-family: $title;
  width: 333px;
  animation: fadeInText 1s ease-in 3.5s forwards, flicker4 5s linear 7.5s infinite;
}

.svgWrap_1,
.svgWrap_2{
  position: absolute;
  height: auto;
  width: 600px;
  max-width: calc(100% - 40px);
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.id1_1,
.id2_1,
.id3_1{
  stroke: $magenta;
  stroke-width: 3px;
  fill: transparent;
}

.id1_2,
.id2_2,
.id3_2{
  stroke: #333;
  stroke-width: 3px;
  fill: transparent;
}

.id3_1{
  stroke-dasharray: 940px;
  stroke-dashoffset: -940px;
  animation: drawLine3 2.5s ease-in-out 0s forwards, flicker3 4s linear 4s infinite;
}

.id2_1{
  stroke-dasharray: 735px;
  stroke-dashoffset: -735px;
  animation: drawLine2 2.5s ease-in-out 0.5s forwards, flicker2 4s linear 4.5s infinite;
}

.id1_1{
  stroke-dasharray: 940px;
  stroke-dashoffset: -940px;
  animation: drawLine1 2.5s ease-in-out 1s forwards, flicker1 4s linear 5s infinite;
}

@keyframes drawLine1 {
  0%  {stroke-dashoffset: -940px;}
  100%{stroke-dashoffset: 0px;}
}

@keyframes drawLine2 {
  0%  {stroke-dashoffset: -735px;}
  100%{stroke-dashoffset: 0px;}
}

@keyframes drawLine3 {
  0%  {stroke-dashoffset: -940px;}
  100%{stroke-dashoffset: 0px;}
}

@keyframes flicker1{
  0%  {stroke: $magenta;}
  1%  {stroke: transparent;}
  3%  {stroke: transparent;}
  4%  {stroke: $magenta;}
  6%  {stroke: $magenta;}
  7%  {stroke: transparent;}
  13% {stroke: transparent;}
  14% {stroke: $magenta;}
  100%{stroke: $magenta;}
}

@keyframes flicker2{
  0%  {stroke: $magenta;}
  50% {stroke: $magenta;}
  51% {stroke: transparent;}
  61% {stroke: transparent;}
  62% {stroke: $magenta;}
  100%{stroke: $magenta;}
}

@keyframes flicker3{
  0%  {stroke: $magenta;}
  1%  {stroke: transparent;}
  10% {stroke: transparent;}
  11% {stroke: $magenta;}
  40% {stroke: $magenta;}
  41% {stroke: transparent;}
  45% {stroke: transparent;}
  46% {stroke: $magenta;}
  100%{stroke: $magenta;}
}

@keyframes flicker4{
  0%  {color: #ff005d;text-shadow:0px 0px 8px #ff005d;}
  30% {color: #ff005d;text-shadow:0px 0px 8px #ff005d;}
  31% {color: #333;text-shadow:0px 0px 4px #333;}
  32% {color: #ff005d;text-shadow:0px 0px 8px #ff005d;}
  36% {color: #ff005d;text-shadow:0px 0px 8px #ff005d;}
  37% {color: #333;text-shadow:0px 0px 4px #333;}
  41% {color: #333;text-shadow:0px 0px 4px #333;}
  42% {color: #ff005d;text-shadow:0px 0px 8px #ff005d;}
  85% {color: #ff005d;text-shadow:0px 0px 8px #ff005d;}
  86% {color: #333;text-shadow:0px 0px 4px #333;}
  95% {color: #333;text-shadow:0px 0px 4px #333;}
  96% {color: #ff005d;text-shadow:0px 0px 8px #ff005d;}
  100%{color: #ff005d;text-shadow:0px 0px 8px #ff005d;}
}

@keyframes fadeInText{
  1%  {color: #333;text-shadow:0px 0px 4px #333;}
  70% {color: #ff005d;text-shadow:0px 0px 14px #ff005d;}
  100%{color: #ff005d;text-shadow:0px 0px 8px #ff005d;}
}
