@import '~styles/variables';
@import '~styles/mixins';

.dropdownOuterwrap {
  background: #0a0a0a;
  height: 100vh;
  margin-top: -15px;
  max-width: $app-width;
  position: fixed;
  top: 80px;
  transform: translateY(0);
  width: 100%;
  z-index: 1000;
}

.overlay {
  @include overlay;
}

.dropdown {
  color: white;
  margin: 0 auto;
  max-width: $container-width;
  padding-top: 80px;
  text-align: left;
}

.titleBlock, .linkBlock {
  opacity: 0;
  transition: opacity 0.4s 0.2s;
}

.active .titleBlock, .active .linkBlock {
  opacity: 1;
}

.titleBlock {
  border-right: 1px solid #777777;
  max-width: 180px;
  padding-right: 85px;
  padding-top: 25px;
  z-index: 1;
}

.header {
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
  margin-bottom: 20px;
}

.subHeader, .linkBody {
  color: #777777;
}

.innerWrap {
  opacity: 0;
  transition: opacity 0.2s 0.3s;
  margin: 0 75px;
  @media screen and (max-width: 512px) {
    margin: 0 15px;
  }

  &.active {
    opacity: 1;
  }
}

.row {
  display: flex;
  width: 100%;
}

.linkBlock {
  display: flex;
  flex-direction: column;
  height: 226px;
  justify-content: space-between;
  margin-left: 8.5%;
  max-width: 180px;
  padding-top: 25px;
  z-index: 1;
}

.linkHeader {
  margin-bottom: 20px;
}

.linkBody {
  font-size: 20px;
}

.button {
  @include learnButton(150px);
  color: white;
  margin-top: 40px;
}

.slant {
  fill: #1d1d1d;
  height: 400px;
  left: 0;
  position: absolute;
  top: 0;
  width: 250px;
}

.navItem {
  align-items: center;
  color: white;
  cursor: pointer;
  font-family: $title;
  font-size: 36px;
  font-weight: bold;
  justify-content: space-between;
  position: relative;
}

.description {
  color: #777;
  font-size: 20px;
}

.navItemBlock {
  margin-bottom: 50px;
}