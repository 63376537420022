@import '~styles/mixins';

.container {
  background: #f7f9fc;
  background-image: url(~images/texture/cyan-cloud.jpg);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  fill: #f7f9fc;
  padding-bottom: 100px;
  position: relative;
}

.layout {
  padding-top: 120px;
  text-align: left;
  .topic,
  .header,
  .subheader {
    @include layout;
  }
}

.topic {
  @include topic;
}

.header {
  @include header;
  max-width: 500px;
}

.subheader {
  @include subheader;
  max-width: 750px;
}

.scrollWrap {
  overflow-x: scroll;
}

.cards {
  display: inline-flex;
  margin-top: 70px;
  gap: 20px;
  padding: 0 calc(min(50vw, 740px) - 637px);
  @media screen and (max-width: $screen-sm-min) {
    padding: 0 15px;
  }
}

.card {
  background: black;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15);
  color: white;
  display: flex;
  height: 600px;
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;
  width: 385px;
  flex-shrink: 0;
  @media screen and (max-width: $screen-sm-min) {
    width: 270px;
  }

  &::after {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), black);
    bottom: 0;
    content: '';
    height: 420px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }

  img {
    position: absolute;
    transform: scale(1);
    transition: transform 1s;
  }
}

.group {
  align-self: flex-end;
  padding: 35px 40px;
  position: relative;
  z-index: 1;
}

.company {
  font-weight: bold;
  margin-bottom: 20px;
}

.subject {
  font-family: $title;
  font-size: 30px;
  line-height: 1em;
  margin-bottom: 30px;
}

.button {
  @include learnButton(180px);
  color: white;
}

@media (min-width: $screen-md-min) {
  .container {
    background-position: 150% 60%;
  }

  .cards {
    width: initial;
  }
}
