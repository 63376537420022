@import '~styles/variables';
@import '~styles/mixins';

.outerWrap {
  background-color: black;
  display: block;
  height: 80px;
  max-width: $app-width;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.topnav {
  align-items: center;
  background-color: black;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  @include layout;
}

.logo {
  margin-top: 8px;
}

.flexWrap {
  align-items: center;
  display: flex;
}

.contact {
  @include learnButton(150px);
  background: none;
  color: $magenta;
  border: 2px solid $magenta;
  &:hover::before {
    background-color: $magenta;
  }
}

.podcast {
  color: $magenta;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 900;
  height: 38px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  transition: color 0.3s;
  z-index: 1;
  background: none;
margin-right: 50px;
  appearance: none;
}

.mobileWrap {
  display: none;
}

.navItem {
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-left: 70px;

  &:not(.link)::after {
    border-right: 2px solid white;
    border-top: 2px solid white;
    content: '';
    display: inline-block;
    height: 4px;
    margin: 0px 8px 0 15px;
    position: relative;
    transform: rotate(135deg);
    transition: all 0.15s ease-in-out;
    width: 4px;
  }

  &.active::after {
    transform: rotate(-45deg);
  }
}

@media (max-width: $screen-lg-max) {
  .flexWrap {
    display: none;
  }

  .mobileWrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .hamburger {
    cursor: pointer;
    height: 40px;
    position: relative;
    width: 40px;

    &:after,
    &:before {
      background: white;
      border-radius: 16px;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: all ease-out 0.2s;
      width: 100%;
    }

    &:before {
      top: 12px;
    }

    &:after {
      top: 27px;
    }

    &.active {
      &:after {
        top: 50%;
        transform: rotate(-45deg);
        transform-origin: center;
      }

      &:before {
        top: 50%;
        transform: rotate(45deg);
        transform-origin: center;
      }

      i {
        opacity: 0;
      }
    }
  }
}
