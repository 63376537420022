@import '~styles/variables';

.slant {
  height: 100px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 100%;
  z-index: 1;

  &.bottom {
    bottom: -420px;
    top: initial;
    transform: translateY(-210px);
  }

  polygon {
    fill: inherit;
  }
}

@media (min-width: $screen-sm-min) {
  .slant {
    height: 210px;
  }
}
