@import '~styles/mixins';
@import '~styles/variables';

.container {
  background: white;
  fill: white;
  padding-bottom: 40px;
  position: relative;
  background-image: url('~images/main-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.layout {
  @include layout;
  display: flex;
  flex-direction: column;
  padding-top: 140px;
}

.gradient {
  @include gradient(#5c96d8, #595cc5)
}

.topic {
  @include topic;
}

.header {
  @include header;

  font-size: 71px;
  line-height: 74px;
}

.subheader {
  @include header;
  font-size: 36px;
  line-height: 48px;
  max-width: 420px;
  padding-top: 50px;
}

.block {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:last-child {
    background-image: none;
  }
}

.icons {
  align-items: center;
  color: #9e9e9e;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0px;
  max-width: 750px;
  padding-top: 40px;

  img {
    height: 70px;
    margin-bottom: 16px;
    object-fit: contain;
    width: 100px;
  }
}

.label {
  margin-bottom: 20px;
}

.iconGroup {
  margin-bottom: 120px;
  max-width: 300px;
  text-align: center;
}

.withPadding {
  padding-top: 140px;
}

@media (min-width: $screen-sm-min) {
  .header {
    font-size: 103px;
    line-height: 107px;
  }

  .icons {
    padding-top: 120px;
    gap: 40px;
  }
}

@media (min-width: $screen-lg-min) {
  .layout {
    flex-direction: row;
  }
}
