@import '~styles/mixins';
@import '~styles/variables';

.container {
  color: white;
  padding: 150px 0 250px;
  position: relative;
}

.layout {
  @include layout;
  padding-bottom: 220px;
  position: relative;
  text-align: left;
}

.topic {
  @include topic();
  font-weight: bold;
}

.topWrap {
  display: flex;
  padding-bottom: 60px;
  position: relative;
}

.titleWrap {
  max-width: 570px;
}

.title {
  @include header();
}

.subheader {
  @include subheader();
}

.bodyWrap {
  column-gap: 20px;
  display: flex;
  flex-direction: column;
}

.textBlock {
  font-size: 20px;
  line-height: 26px;
  width: 100%;
}

.tabletImg {
  display: none;
  position: absolute;
  right: 0;
  z-index: 2;
  top: -410px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.containerBackground {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  object-position: left;
}

@media (min-width: $screen-sm-min) {
  .tabletImg {
    display: block;
    left: 650px;
    top: -120px;
    right: unset;
    margin: unset;
  }

  .bodyWrap {
    flex-direction: row;
    justify-content: space-between;
  }

  .textBlock {
    max-width: 400px;
  }

  .topWrap {
    padding-bottom: 150px;
  }
}

@media (min-width: $screen-lg-min) {
  .tabletImg {
    right: 0;
    top: -320px;
    width: 681px;
  }

  .layout {
    padding-bottom: 130px;
  }
}
