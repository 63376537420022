@import '~styles/variables';

@mixin overlay() {
  animation: fadeIn 0.3s forwards;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  left: 50%;
  opacity: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  will-change: opacity;
  z-index: 1;
}

@mixin topic() {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

@mixin header() {
  font-family: $title;
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 20px;
}

@mixin subheader() {
  font-size: 24px;
  font-weight: 400;
}

@mixin layout {
  margin: 0 auto;
  max-width: $container-width;
  padding: 0 75px;
  @media screen and (max-width: 512px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@mixin learnButton($width: 210px) {
  align-items: center;
  border: 2px solid white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 900;
  height: 38px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  transition: color 0.3s;
  width: $width;
  z-index: 1;
  background: none;
  padding: 0;
  appearance: none;

  &:before {
    background: #37474f;
    content: '';
    height: calc(100% + 2px);
    left: 0;
    position: absolute;
    top: 0;
    transform: translate3d(-100%, 0, 0) skew(-50deg);
    transform-origin: 100% 0;
    transition: transform 0.4s, opacity 0.3s, background-color 0.3s;
    width: 150%;
    z-index: -1;
  }

  &:hover {
    color: black;
  }

  &:hover::before {
    background-color: white;
    opacity: 1;
    transform: rotate3d(0, 0, 1, 0deg) skew(-50deg);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
}

@mixin gradient($color1, $color2) {
  -webkit-background-clip: text;
  background-image: linear-gradient(0deg, $color1, $color2);
  -webkit-text-fill-color: transparent;
}
