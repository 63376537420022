// Grid
$app-width: 1480px;
$container-width: 1275px;
$screen-sm-max: 767px;
$screen-sm-min: 768px;
$screen-md-max: 991px;
$screen-md-min: 992px;
$screen-lg-max: 1279px;
$screen-lg-min: 1280px;

// Colors
$magenta: #f73b79;

// Font
$title: bebas-neue-pro-expanded, sans-serif;
