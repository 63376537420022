@import '~styles/mixins';
@import '~styles/variables';

.container {
  background: white;
  fill: white;
  padding-bottom: 40px;
  position: relative;
}

.layout {
  align-items: center;
  display: flex;
  flex-direction: column;
  @include layout;
}

.header {
  @include header;
  align-self: flex-start;
}

.subheader {
  @include subheader;
  margin-top: 18px;
  max-width: 780px;
  text-align: left;
}

.block {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;

  .block:last-child {
    background-image: none;
  }
}

.logos {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, minmax(160px, 1fr));
  justify-content: center;
  max-width: $container-width;
  padding: 80px 0 20px;
  position: relative;

  // &::after {
  //   content: '';
  //   height: 150px;
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  // }

  img {
    height: 70px;
    margin: 0 40px 70px;
    object-fit: contain;
    width: 100px;
  }
}

.withPadding {
  padding-top: 140px;
}

@media (min-width: $screen-sm-min) {
  .column {
    flex-direction: row;
  }

  .header {
    align-self: center;
  }

  .logos {
    grid-template-columns: repeat(4, minmax(160px, 1fr));
    img {
      margin: 0 50px 70px;
    }
  }

  .subheader {
    text-align: center;
  }
}

@media (min-width: $screen-lg-min) {
  .logos {
    gap: 100px 70px;
  }

  .column {
    flex-direction: row;
  }

  .header {
    padding-top: 40px;
  }

  .subheader {
    text-align: center;
  }

  .block {
    padding-top: 120px;
  }
}
