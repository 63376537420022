@import '~styles/mixins';

.container {
  background: #f4f4f4;
  position: relative;
  flex-direction: column;
  display: flex;
}

.layout {
  padding: 70px 0;
  display: flex;
  width: 100%;
}

.innerLayout {
  max-width: 550px;
  padding: 0 40px;
}

.title {
  @include header;
  -webkit-background-clip: text;
  background-image: linear-gradient(0deg, #5c96d8, #595cc5 90%);
  line-height: 50px;
  -webkit-text-fill-color: transparent;
}

.body {
  color: #595959;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}

.image {
  display: none;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 400px;
  background-position: center;
}

// .moreDetails {
//   cursor: pointer;
//   text-transform: uppercase;

//   img {
//     margin-left: 8px;
//     margin-top: 75px;
//   }
// }

@media (min-width: $screen-sm-min) {
  .image {
    min-height: 700px;
    display: block;
  }

  .layout {
    padding: 150px 0;
  }
}

@media (min-width: $screen-lg-min) {
  .container {
    flex-direction: row;
    height: 840px;

    &.large {
      height: 1000px;
    }
  }

  .layout {
    padding: 150px 0 0;
    justify-content: center;
  }

  .image {
    min-height: initial;
    background-position: initial;

    &.right {
      order: 1;
    }
  }
}
