@import '~styles/mixins.scss';

.container {
  height: 250px;
  position: relative;
}

.layout {
  @include layout;
}

.page {
  position: absolute;
  z-index: 1;
}

.left {
  left: 0;
  top: -380px;
  width: 100vw;
}

.right {
  right: 0px;
  top: 600px;
  width: 100vw;
}

.top,
.fade,
.right {
  display: none;
}

.rectangle {
  background-image: url('~images/rectangle_2.png');
  color: white;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  left: 0;
  padding: 40px;
  position: absolute;
  text-align: left;
  bottom: 0;
  z-index: 3;
  width: 100%;
}

.body {
  font-family: $title;
  font-size: 24px;
  margin-bottom: 30px;
  position: relative;
  margin: 50px 0;
  &::before {
    content: '“';
    position: absolute;
    left: -82px;
    color: var(--color);
    font-size: 19rem;
    z-index: -1;
    top: -145px;
  }
}

.yellow {
  --color: #f6a126;
}

.orange {
  --color: #dd5a33;
}

.green {
  --color: #06a6a9;
}

@media (min-width: $screen-sm-min) {
  .container {
    height: 700px;
  }
}

@media (min-width: $screen-md-min) {
  .container {
    height: 1170px;
  }

  .layout {
    position: relative;
  }

  .left {
    left: 75px;
    top: 560px;
    width: initial;
  }

  .right {
    right: 0;
    top: 700px;
    width: initial;
  }

  .rectangle {
    left: 20px;
    width: 360px;
  }

  .top {
    right: 200px;
    top: 0;
  }

  .top,
  .fade,
  .right {
    display: block;
  }

  .fade {
    left: 0;
    max-width: 100vw;
    position: relative;
    z-index: 1;
    top: -350px;
  }

  .left,
  .top {
    display: none;
  }

  .rectangle {
    bottom: unset;
    top: 110px;
  }

  .page {
    max-width: 675px;
  }
}

@media (min-width: $screen-md-min) {
  .left {
    display: block;
  }

  .top {
    right: 40px;
    top: 150px;
    display: block;
  }

  .fade {
    left: 280px;
    max-width: 1010px;
    top: -350px;
  }
}
