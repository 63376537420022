@import '~styles/mixins';
@import '~styles/variables';

.layout {
  @include layout;
  padding-bottom: 120px;
  text-align: left;
}

.summary {
  color: #282728;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 300;
  justify-content: space-between;
  line-height: 36px;
  padding-top: 120px;
  gap: 30px;
}

.title {
  background-clip: text;
  background-image: linear-gradient(0deg, #5c96d8, #595cc5 90%);
  font-family: $title;
  font-size: 48px;
  font-weight: 700;
  height: 100%;
  line-height: 48px;
  max-width: 550px;
  min-width: 250px;
  color: transparent;
  flex-grow: 1;
}

.body {
  flex-grow: 2;
  // max-width: 555px;
}

.listWrapper {
  flex-direction: column;
  justify-content: space-between;
  padding-top: 100px;
  display: none;
}

.header {
  border-bottom: 1px solid black;
  font-size: 18px;
  margin-bottom: 20px;
  padding-bottom: 19px;
  text-transform: uppercase;
}

.list {
  margin-bottom: 40px;
  display: none;
  width: 33%;
  flex-grow: 1;
}

.list li {
  color: #282728;
  list-style-type: none;
  margin-bottom: 15px;
  padding: 0;
}

@media (min-width: $screen-sm-min) {
  .layout {
    padding-bottom: 120px;
  }
}

@media (min-width: $screen-md-min) {
  .summary {
    flex-direction: row;
    padding-top: 120px;
  }

  .title {
    margin-bottom: 0;
  }

  .listWrapper {
    flex-direction: row;
    padding-top: 120px;
    display: flex;
    gap: 30px;
  }

  .list {
    margin-bottom: 0;
    display: block;
  }
}
