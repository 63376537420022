@import '~styles/mixins';

.container {
  background: white;
  fill: white;
  padding-bottom: 140px;
  position: relative;
}

.layout {
  @include layout;
}

.row {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  max-width: 100%;
}

.block {
  padding-top: 80px;
  text-align: left;
  // max-width: 400px;

  &:last-child {
    background-image: none;
  }
}

.header {
  @include header;
  padding-top: 80px;
  text-align: center;
  font-size: 42px;
}

.subheader {
  @include subheader;
  margin: 0 auto;
  max-width: 425px;
  font-size: 20px;
  text-align: center;
}

.title {
  @include header;
  font-size: 36px;
  line-height: 36px;
  margin: 0 0 20px 0;
  max-width: 200px;
  text-align: left;
}

.body {
  color: #bbbbbb;
  font-size: 20px;
  // max-width: 100%;
  min-width: 275px;
}

.learnmore {
  cursor: pointer;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin-top: 30px;
  text-transform: uppercase;
}

@media (min-width: $screen-sm-min) {
  .block {
    text-align: left;
    max-width: 100%;
  }

  .row {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 700px;
  }

  .allLinks .block {
    max-width: 260px;
  }

  .title {
    margin: 0 0 20px 0;
  }
}

@media (min-width: $screen-md-min) {
  .subheader {
    max-width: unset;
  }
  .block{
    max-width: unset;
  }
}

@media (min-width: $screen-lg-min) {
  .header, .subheader {
    text-align: left;
  }

  .header {
    padding-top: 120px;
    font-size: 42px;
  }

  .subheader {
    font-size: 24px;
  }

  .container {
    padding-bottom: 0;
  }

  .block {
    height: 400px;
    margin-bottom: 100px;
    max-width: 325px;
  }
  .body{
    max-width: 100%;
  }

  .row {
    max-width: 100%;
    justify-content: space-around;

    &::after {
      background: linear-gradient(white, transparent);
      content: '';
      height: 100px;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &::before {
      background: linear-gradient(transparent, white);
      bottom: 0;
      content: '';
      height: 100px;
      position: absolute;
      width: 100%;
    }
  }
}

.button {
  @include learnButton(150px);
  border: 2px solid black;
  color: black;
  margin-top: 40px;

  &.active {
    background-color: black;
    color: white;
  }
  &:hover {
    color: white;
  }
  &:hover::before {
    background-color: black
  }
}