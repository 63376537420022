@import '~styles/mixins.scss';

.container {
  height: 600px;
  position: relative;
}

.layout {
  @include layout;
}

.page {
  position: absolute;
}

.left {
  left: 0;
  top: -380px;
  width: 100vw;
}

.right {
  right: 0px;
  top: 600px;
  width: 100vw;
  display: none;
}

.rectangle {
  background-image: url('~images/rectangle_1.jpg');
  background-size: cover;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  left: 0;
  padding: 40px;
  position: absolute;
  text-align: left;
  bottom: 0px;
  width: 100%;
}

.body {
  font-family: $title;
  font-size: 24px;
  margin-bottom: 30px;
  position: relative;
  margin: 50px 0;
  z-index: 1;
  &::before {
    content: '“';
    position: absolute;
    left: -82px;
    color: #116bc0;
    font-size: 19rem;
    z-index: -1;
    top: -145px;
  }
}
@media (min-width: $screen-sm-min) {
  .container {
    height: 900px;
  }
}

@media (min-width: $screen-md-min) {
  .container {
    height: 1170px;
  }

  .left {
    left: 175px;
    top: -220px;
    display: none;
    width: initial;
  }

  .right {
    display: block;
    right: 100px;
    top: -340px;
    width: 70%;
  }

  .rectangle {
    height: 525px;
    left: 20px;
    width: 360px;
    top: 110px;
    bottom: unset;
  }
}

@media (min-width: $screen-lg-min) {
  .left {
    display: block;
  }

  .right {
    width: initial;
    right: 0;
  }
}
