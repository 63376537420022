@import '~styles/variables';
@import '~styles/mixins';

.disabled {
  opacity: 0;
  pointer-events: none;
}

.bg {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: scroll;
  align-items: center;
  transition: all 0.1s ease-in-out;
  div {
    width: 100%;
    max-width: 600px;
    margin: 10px auto;
    z-index: 100;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 620px) {
      width: 300px;
    }
    iframe {
      width: 100%;
      border: none;
      border-radius: 5px;
      
    }
    &::after {
      content: '';
      border-radius: 15px;
      position: absolute;
      top: 10px;
      left: 10px;
      right: -10px;
      bottom: -10px;
      z-index: -2;
      background-color: rgba(0, 0, 0, 0.1);
      mix-blend-mode: multiply;
    }
  }
}
