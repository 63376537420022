@import '~styles/variables';

.footer {
  align-items: center;
  background: black;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
}

.innerWrap {
  width: 90%;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 12px;

  a {
    color: #f9f9f9;
    &:hover {
      color: #f73b79;
      transition: .25s ease-in;
    }
  }
}
