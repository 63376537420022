@import '~styles/variables';
@import '~styles/mixins';

.disabled {
  opacity: 0;
  pointer-events: none;
}

.bg {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  transition: all 0.1s ease-in-out;
  box-sizing: border-box;

  form {
    width: calc(100% - 10px);
    max-width: 750px;
    margin: 100px auto;
    z-index: 100;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: -1;
      background-color: white;
    }
    &::after {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      right: -10px;
      bottom: -10px;
      z-index: -2;
      background-color: rgba(0, 0, 0, 0.1);
      mix-blend-mode: multiply;
    }
    svg {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      z-index: -1;
    }
    button[type='button'] {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
      z-index: 100;
      background: transparent;
      border: none;
      font-size: 36px;
      cursor: pointer;
      width: 60px;
      height: 60px;
      color: #aaa;
    }
    header,
    main,
    footer {
      width: calc(100% - 100px);
    }
    header {
      display: flex;
      gap: 30px;
      margin: 50px auto 0;
      > * {
        width: 50%;
      }
      @media screen and (max-width: 500px) {
        flex-direction: column;
        > * {
          width: auto;
        }
      }

      h1 {
        font-family: $title;
        font-size: 40px;
        font-weight: 700;
        line-height: 36px;
      }
      p {
        font-size: 11px;
      }
    }
    main {
      width: calc(100% - 100px);
      margin: auto;
      display: flex;
      flex-direction: row;
      gap: 30px;
      @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 15px;
      }
      > section:first-of-type div {
        flex-grow: 1;
      }
      section {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        gap: 15px;
        div {
          label {
            display: inline;
            position: relative;
            margin-left: 5px;
            font-size: 16px;
            z-index: 100;
            padding: 0 3px;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: -1;
              background-color: white;
              height: 8px;
            }
          }
          select {
            -webkit-appearance: none;
          }
          textarea {
            height: calc(100% - 12px);
            min-height: 200px;
            min-width: 270px;
          }
          input,
          textarea,
          select {
            background-color: white;
            box-sizing: border-box;
            width: 100%;
            margin-top: -8px;
            font-size: 20px;
            padding: 10px;
            border: 1px solid black;
            border-radius: 0px;
            &:focus {
              outline: none;
              border-color: $magenta;
            }
          }
        }
      }
    }
    footer {
      width: calc(100% - 100px);
      margin: 0 auto 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      button[type='submit'] {
        @include learnButton(150px);
        width: auto;
        padding: 15px 40px 12px;
        font-size: 16px;
        border: none;
        background: $magenta;
        text-transform: uppercase;
        font-weight: bold;
        color: white;
        &:hover {
          color: white;
          &::before {
            background-color: black;
          }
        }
      }
    }
  }
}
