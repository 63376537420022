@import '~styles/mixins';

.container {
  background: url('~images/texture/dark-angle.jpg');
  background-position: 0 -1px;
  background-size: cover;
  box-sizing: border-box;
  color: white;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}

.layout {
  @include layout;
  padding-top: 150px;
  text-align: left;
  @media screen and (max-width: 512px) {
    padding-top: 120px;
  }
}

.block {
  margin-bottom: 90px;
}

.topic {
  @include topic;
  text-transform: uppercase;
}

.header {
  @include header;
}

.body {
  color: white;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 30px;
}

@media (min-width: $screen-md-min) {
  .container {
    background: url('~images/parallax/green-flat.png');
  }

  .layout {
    padding-top: 235px;
  }

  .body {
    max-width: 680px;
  }
}
